import React from 'react';
import {withRouter} from 'react-router';
import sortArray from '../../util/sortArray';
import formatDate from '../../util/formatDate';
import {CLIENT_LIST_INDEX} from '../../configuration';
import Loading from '../element/miscellaneous/Loading';
import isAuthorized from '../../util/isAuthorized';

export default withRouter(
	class ClientSidebarList extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				result: {},
				loaded: false,
				triggered: 0,
			};
		}

		componentDidMount = () => {
			this.handleFilterAndSort();
		};

		componentDidUpdate = () => {
			this.handleFilterAndSort();
		};

		handleFilterAndSort = () => {
			const {search, sortby, order, clients, trigger} = this.props;
			const {triggered} = this.state;

			if (triggered !== trigger) {
				let result = [];
				if (search !== '') {
					result = clients.filter(client => {
						const string = `
                        ${client.voorletters} ${client.roepnaam} ${client.tussenvoegsel} ${client.achternaam}
                        ${client.gemeente_naam}
                        ${client.bsn}
                        ${client.geboortefilter}
                        ${client.id}`;
						return !client.verwijderd ? string.toLowerCase().includes(search.toLowerCase()) : null;
					});
				} else {
					result = clients.filter(client => {
						return !client.verwijderd ? client : null;
					});
				}
				const sorted = sortArray(result, order, sortby);

				this.setState(state => {
					state.result = sorted;
					state.loaded = true;
					state.triggered = trigger;
					return state;
				});
			}
		};

		handleListItem = (client, props) => {
			const {handleEdit, confirmArchive, autorisatie} = props;

			return (
				<li className={props.location.pathname === `/client/${client.id}` ? 'current_client' : ''} key={'client' + client.id.toString()} onClick={event => this.props.handleChooseClient(client, props, event)}>
					<p title={client.naam}>{client.naam}</p>
					<span>
						{isAuthorized(autorisatie, 'client.wijzigen') && <button className='edit' onClick={event => handleEdit(client.id, event)}></button>}
						{isAuthorized(autorisatie, 'client.archiveren') && <button className='archive' onClick={event => confirmArchive(client.id, event)}></button>}
					</span>
				</li>
			);
		};

		render = () => {
			const {result, loaded} = this.state;
			const {sortby, order} = this.props;

			if (!loaded) return <Loading message='' />;
			let index = '';

			const multiple = result.length !== 1 ? 'en' : '';
			const info = `${result.length} cliënt${multiple}, gesorteerd op ${sortby}, ${order ? 'oplopend' : 'aflopend'}`;

			return (
				<>
					<section className='info'>{info}</section>
					<main className='list'>
						<ul>
							{result.map(client => {
								let renderIndex = false;

								if (sortby === 'achternaam') {
									if ((index === '' || index !== client[sortby].charAt(0)) && result.length >= CLIENT_LIST_INDEX) {
										index = client[sortby].charAt(0);
										renderIndex = true;

										return (
											<React.Fragment key={'index' + client.id.toString()}>
												{renderIndex && <li className='index'>{index}</li>}
												{this.handleListItem(client, this.props)}
											</React.Fragment>
										);
									}
									return this.handleListItem(client, this.props);
								}
								if ((index === '' || index !== client[sortby]) && result.length >= CLIENT_LIST_INDEX) {
									index = client[sortby];
									renderIndex = true;

									return (
										<React.Fragment key={'index' + client.id.toString()}>
											{renderIndex && <li className='index'>{formatDate(index, 2)}</li>}
											{this.handleListItem(client, this.props)}
										</React.Fragment>
									);
								}
								return this.handleListItem(client, this.props);
							})}
						</ul>
					</main>
				</>
			);
		};
	},
);

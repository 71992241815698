// @ ts-nocheck
import React from 'react';
import api from '../../util/apiRequest';
import removeDuplicates from '../../util/removeDuplicates';
import formatDate from '../../util/formatDate';
import isAuthorized from '../../util/isAuthorized';
import notEmpty from '../../util/notEmpty';
import {FILTER_DATE_MAX} from '../../configuration';
import { Client, DbUser, HandleForm, Toewijzing, VerantwoordingLegacy } from '../../types';

interface Contract {
	'id': number,
	'categorie': string,
	'categorie_label': string,
	'wet': 'wmo' | 'jw',
	'product': string,
	'product_label': string,
	'begindatum': string,
	'einddatum': string,
	'eenheid': string,
	'eenheid_label': string,
	'frequentie': string,
	'frequentie_label': string,
	'periode': number,
	'periode_label': string,
	'agb_code': string,
	'raamcontract': number,
	'gemeente_id': number,
	'gemeente_code': string,
	'gemeente_naam': string,
	'tarief': number,
	'tarief_begindatum': string,
	'tarief_einddatum': string,
}

interface Props {
	form: {
		formid: 'verantwoording-wijzigen' | 'verantwoording-toevoegen' | 'verantwoording-verwijderen'
		data: {
			toewijzing: Toewijzing,
			dialogWidth: number | string,
			contract_regel_id?: number
			id: string
			contracts: Contract[],
			eenheid: string,
			eenheid_label: string,
			formid: 'verantwoording-wijzigen' | 'verantwoording-toevoegen' | 'verantwoording-verwijderen',
			result: string,
			datum: string,
			einddatum: string,
			categorie: string
			categorie_label: string
			product_code: string
			product_name: string,
			volume: number
		},
		verantwoordingen: VerantwoordingLegacy[]
	},
	client: Client,
	dbUser: DbUser,
	handleForm: HandleForm,
	handleVerantwoordingTable: (arg: unknown) => void,
	periods: {
		type: number
		begindatum: string
		einddatum: string
	}[],
	retourcodes: unknown,
}

interface State {
	datum: string,
	einddatum: string | false,
	categorie: {categorie: string, categorie_label: string},
	categorieList: {categorie: string, categorie_label: string}[],
	product: {product: string, product_label: string},
	productList: {product: string, product_label: string}[],
	eenheid: {eenheid: string, eenheid_label: string},
	eenheidList: {eenheid: string, eenheid_label: string}[],
	limits: {
		start_min: string | undefined
		start_max: string | undefined
		end_min: string | undefined
		end_max: string | undefined
	},
	generic: unknown
	aspecific: unknown
	client: Props['client']
	contracts: Contract[]
	toewijzing: Props['form']['data']['toewijzing']
	wet: 'jw' | 'wmo'
}

function tryFormatDate(date: string | undefined | Date | false, format: 1 | 2 | 3 | 4 | 5) {
	return date ? formatDate(date, format) : undefined;
}

export default class FormAllocationStatement extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const {data} = props.form;
		this.state = {
			datum: notEmpty(data.datum) ? data.datum : '',
			einddatum: notEmpty(data.datum) && (notEmpty(data.einddatum) ? data.einddatum : ''),
			categorie: {
				categorie: data.categorie,
				categorie_label: data.categorie_label,
			},
			categorieList: [],
			product: {
				product: data.product_code,
				product_label: data.product_name,
			},
			productList: [],
			eenheid: {
				eenheid: data.eenheid,
				eenheid_label: data.eenheid_label,
			},
			eenheidList: [],
			limits: {
				start_min: undefined,
				start_max: undefined,
				end_min: undefined,
				end_max: undefined,
			},
			generic: undefined,
			aspecific: undefined,
			client: props.client,
			contracts: [],
			toewijzing: props.form.data.toewijzing,
			wet: 'jw',
		};
	}

	componentDidMount = () => {
		const {client, form} = this.props;
		const {data} = form;
		const {toewijzing, contracts} = data;
		const {wet} = toewijzing;

		const generic = (toewijzing.categorie === null || toewijzing.categorie === '00') && toewijzing.product === null ? true : false;
		const aspecific = toewijzing.categorie !== null && toewijzing.product === null ? true : false;

		const cat = this.updateCategorie({
			generic: generic,
			client: client,
			contracts: contracts,
			toewijzing: toewijzing,
			datum: this.state.datum,
			wet,
		});

		const categorie = cat.categorie;
		const categorieList = cat.categorieList;

		const prod = this.updateProduct({
			generic,
			aspecific,
			categorie: categorie.categorie,
			client,
			contracts,
			toewijzing,
			wet,
			datum: this.state.datum,
		});

		const product = prod.product;
		const productList = prod.productList;

		const eenh = this.updateEenheid({
			categorie: categorie.categorie,
			product: product.product,
			client: client,
			contracts: contracts,
			datum: this.state.datum,
		});

		let eenheid = eenh.eenheid;
		const eenheidList = eenh.eenheidList;

		if (form.formid === 'verantwoording-wijzigen') {
			eenheid = {eenheid: data.eenheid, eenheid_label: data.eenheid_label};
		}

		const limits = this.handleDateLimits(notEmpty(data.datum) ? data.datum : '');

		this.setState({
			wet,
			generic,
			aspecific,
			client,
			contracts,
			toewijzing,
			categorie,
			categorieList,
			product,
			productList,
			eenheid,
			eenheidList,
			limits,
		});
	};

	handleCategorie = value => {
		const {generic, aspecific, client, contracts, toewijzing, datum, wet} = this.state;

		const prod = this.updateProduct({
			generic: generic,
			aspecific: aspecific,
			categorie: value,
			client: client,
			contracts: contracts,
			toewijzing: toewijzing,
			datum,
			wet,
		});

		const eenh = this.updateEenheid({
			categorie: value,
			client: client,
			contracts: contracts,
			product: prod.product.product,
			datum,
		});

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const categorie = contracts.find(row => {
			return row.categorie === value;
		})!;

		return this.setState({
			categorie: {categorie: categorie.categorie, categorie_label: categorie.categorie_label},
			product: prod.product,
			productList: prod.productList,
			eenheid: eenh.eenheid,
			eenheidList: eenh.eenheidList,
		});
	};

	updateCategorie = ({generic, contracts, toewijzing, datum, wet}: Pick<State, 'generic' | 'client' | 'contracts' | 'toewijzing' | 'datum' | 'wet'>) => {
		const {form: {data: { contract_regel_id}}} = this.props;

		datum = datum ? datum + 'T00:00:00.000Z' : datum; // The rows in the contract list include this part

		let categorieL: {categorie: string, categorie_label: string}[];

		if (generic === true) {
			categorieL = contracts
				.filter(row => contract_regel_id ? row.id === contract_regel_id : (
					toewijzing.gemeente_id === row.gemeente_id &&
                    row.wet === wet &&
                    (row.begindatum === null || !datum || datum >= row.begindatum) &&
                    (row.einddatum === null || !datum  || datum <= row.einddatum)
				))
				.map(row => {
					return {categorie: row.categorie, categorie_label: row.categorie_label};
				});
		} else {
			categorieL = contracts
				.filter(row => contract_regel_id ? row.id === contract_regel_id : (
					toewijzing.gemeente_id === row.gemeente_id &&
                    toewijzing.categorie === row.categorie &&
                    (row.begindatum === null || !datum || datum >= row.begindatum) &&
                    (row.einddatum === null || !datum || datum <= row.einddatum)
				))
				.map(row => {
					return {categorie: row.categorie, categorie_label: row.categorie_label};
				});
		}
		const categorieList = removeDuplicates(categorieL, 'categorie');

		const categorie: (typeof categorieL)[number] = categorieList.find(e => e.categorie === this.state.categorie.categorie) ?? categorieList[0] ?? {
			categorie: '',
			categorie_label: '',
		};

		return {
			categorieList,
			categorie,
		};
	};

	handleProduct = value => {
		const {client, contracts, categorie, datum} = this.state;

		const eenh = this.updateEenheid({
			categorie: categorie.categorie,
			client: client,
			contracts: contracts,
			product: value,
			datum,
		});

		const product = contracts.find(row => {
			return row.product === value;
		}) ?? {product: '', product_label: ''};

		this.setState({
			product: {product: product.product, product_label: product.product_label},
			eenheid: eenh.eenheid,
			eenheidList: eenh.eenheidList,
		});
	};

	updateProduct = ({generic, aspecific, categorie, contracts, toewijzing, datum}: Pick<State, 'contracts' | 'client' | 'toewijzing' | 'generic' | 'aspecific' | 'datum' | 'wet'> & { categorie: string}) => {
		//
		const {form: {data: { contract_regel_id}}} = this.props;

		datum = datum ? datum + 'T00:00:00.000Z' : datum; // The rows in the contract list include this part

		let productL: {product: string, product_label: string}[];

		if (generic === true || aspecific === true) {
			productL = contracts
				.filter(row => contract_regel_id ? row.id === contract_regel_id : (
					toewijzing.gemeente_id === row.gemeente_id &&
                    categorie === row.categorie &&
                    (row.begindatum === null || !datum || datum >= row.begindatum) &&
                    (row.einddatum === null || !datum || datum <= row.einddatum)
				))
				.map(row => {
					return {
						categorie: row.categorie,
						categorie_label: row.categorie_label,
						product: row.product,
						product_label: row.product_label,
					};
				});
		} else {
			productL = contracts
				.filter(
					row => contract_regel_id ? row.id === contract_regel_id : (
						toewijzing.gemeente_id === row.gemeente_id &&
                        categorie === row.categorie &&
                        toewijzing.product === row.product &&
                        (row.begindatum === null || !datum || datum >= row.begindatum) &&
                        (row.einddatum === null || !datum || datum <= row.einddatum)
					),
				)
				.map(row => {
					return {
						categorie: row.categorie,
						categorie_label: row.categorie_label,
						product: row.product,
						product_label: row.product_label,
					};
				});
		}

		const productList = removeDuplicates(productL, 'product');

		const product: (typeof productL)[number] = productList.find(e => e.product === this.state.product.product) ?? productList[0] ?? {
			product: '',
			product_label: '',
		};

		return {
			productList,
			product,
		};
	};

	handleEenheid = (value) => {
		const {contracts, categorie, product, datum} = this.state;
		const {form: {data: {toewijzing, contract_regel_id}}} = this.props;

		// Indien een toewijzing een eenheid heeft, mag de eenheid niet worden gewijzigd (behalve bij minuten/uren).
		// In alle andere gevallen is de eenheid gelijk aan die van de toewijzing.
		// Indien de toewijzing geen eenheid heeft (bv bij aspecifieke toewijzing), moet je de gecontracteerde eenheden kunnen kiezen.
		const eenheid = contracts
			.find(
				row => contract_regel_id ? row.id === contract_regel_id : (
					(
						toewijzing.eenheid === '01' || toewijzing.eenheid === '04' ? row.eenheid === '01' || row.eenheid === '04' :
							toewijzing.eenheid ? row.eenheid === toewijzing.eenheid :
								true
					) &&
                    toewijzing.gemeente_id === row.gemeente_id &&
                    categorie.categorie === row.categorie &&
                    product.product === row.product &&
                    row.eenheid === value &&
                    (row.begindatum === null || !datum || datum >= row.begindatum) &&
                    (row.einddatum === null || !datum || datum <= row.einddatum)
				),
			) ?? {eenheid: '', eenheid_label: ''};

		this.setState({
			eenheid,
		});
	};

	updateEenheid = ({categorie, product, contracts, datum}: Pick<State, 'contracts' | 'client' | 'datum'> & { product: string, categorie: string}) => {
		const {form: {data: {toewijzing, contract_regel_id}}} = this.props;

		datum = datum ? datum + 'T00:00:00.000Z' : datum; // The rows in the contract list include this part

		const eenheidL = contracts
			.filter(
				row => contract_regel_id ? row.id === contract_regel_id : (
					(
						toewijzing.eenheid === '01' || toewijzing.eenheid === '04' ? row.eenheid === '01' || row.eenheid === '04' :
							toewijzing.eenheid ? row.eenheid === toewijzing.eenheid :
								true
					) &&
                    toewijzing.gemeente_id === row.gemeente_id &&
                    categorie === row.categorie &&
                    product === row.product &&
                    (row.begindatum === null || !datum || datum >= row.begindatum) &&
                    (row.einddatum === null || !datum || datum <= row.einddatum)
				),
			)
			.map(row => {
				return {
					eenheid: row.eenheid,
					eenheid_label: row.eenheid_label,
				};
			});

		const eenheidList = removeDuplicates(eenheidL, 'eenheid');

		const eenheid = eenheidList.find(e => e.eenheid === this.state.eenheid.eenheid) ?? eenheidList[0] ?? {
			eenheid: '',
			eenheid_label: '',
		};
		return {
			eenheidList,
			eenheid,
		};
	};

	handleCancel = () => {
		this.props.handleForm(null);
	};

	calculateContractId = () => {
		const {form: {data: {toewijzing, contracts}}} = this.props;
		console.log(this);
		const {categorie, eenheid, product, datum} = this.state;

		const datum1 = datum ? datum + 'T00:00:00.000Z' : datum; // The rows in the contract list include this part
		const list = contracts
			.filter(
				row =>
					toewijzing.gemeente_id === row.gemeente_id &&
                    categorie.categorie === row.categorie &&
                    product.product === row.product &&
                    eenheid.eenheid === row.eenheid &&
                    (row.begindatum === null || datum1 >= row.begindatum) &&
                    (row.einddatum === null || datum1 <= row.einddatum),
			);
		console.log('Selecting the first valid contract from:', list);
		return list[0]?.id ?? null;
	};

	handleSubmit = async (event, id) => {
		event.preventDefault();
		const formid = this.props.form.formid;
		const client_data = this.props.client;
		const data: Record<string, any> = {id: id, contractregel_id: this.props.form.data.contract_regel_id ?? this.calculateContractId()};
		const {token} = this.props.dbUser;
		const elements = event.target.elements;
		Object.keys(elements).forEach(key => {
			const element = elements[key];
			if (element.type !== 'submit' && element.name !== '') {
				data[element.name] = element.value;
			}
		});

		if (formid === 'verantwoording-wijzigen') {
			const updateVerantwoording = await api.updateVerantwoording(
				client_data.aanbieder_id,
				client_data.id,
				data.toewijzing_id,
				id,
				data,
				api.makeHeader(token),
			);
			if (updateVerantwoording.error && updateVerantwoording.error === true) {
				this.props.handleForm(updateVerantwoording);
				return null;
			}
			data.result = updateVerantwoording.response;
			data.formid = formid;
			this.props.handleForm(null);
			this.props.handleVerantwoordingTable(data);
		}

		if (formid === 'verantwoording-toevoegen') {
			const addVerantwoording = await api.addVerantwoording(
				client_data.aanbieder_id,
				client_data.id,
				data.toewijzing_id,
				data,
				api.makeHeader(token),
			);
			if (addVerantwoording.error && addVerantwoording.error === true) {
				this.props.handleForm(addVerantwoording);
				return null;
			}
			data.result = addVerantwoording.response;
			data.formid = formid;
			this.props.handleForm(null);
			this.props.handleVerantwoordingTable(data);
		}

		if (formid === 'verantwoording-verwijderen') {
			const archiveVerantwoording = await api.archiveVerantwoording(
				client_data.aanbieder_id,
				client_data.id,
				data.toewijzing_id,
				id,
				api.makeHeader(token),
			);
			if (archiveVerantwoording.error && archiveVerantwoording.error === true) {
				this.props.handleForm(archiveVerantwoording);
				return null;
			}
			data.result = archiveVerantwoording.response;
			data.formid = formid;
			this.props.handleForm(null);
			this.props.handleVerantwoordingTable(data);
		}
	};

	handleDatum = (date = '') => {
		const {generic, aspecific, client, contracts, toewijzing, wet} = this.state;
		const limits = this.handleDateLimits(date);
		const start_date = date;



		const categorie = this.updateCategorie({
			generic: generic,
			client: client,
			contracts: contracts,
			toewijzing: toewijzing,
			datum: date,
			wet,
		});


		const product = this.updateProduct({
			generic: generic,
			aspecific: aspecific,
			categorie: categorie.categorie.categorie,
			client: client,
			contracts: contracts,
			toewijzing: toewijzing,
			datum: date,
			wet,
		});

		const eenh = this.updateEenheid({
			categorie: categorie.categorie.categorie,
			client: client,
			contracts: contracts,
			product: product.product.product,
			datum: date,
		});

		this.setState(() => ({
			datum: tryFormatDate(limits.start_max && start_date > limits.start_max ? limits.start_max : start_date, 1) ?? '',
			einddatum: '',
			limits: limits,
			categorie: categorie.categorie,
			product: product.product,
			productList: product.productList,
			eenheid: eenh.eenheid,
			eenheidList: eenh.eenheidList,
		}));
	};

	handleEindDatum = (date = '') => {
		const limits = this.state.limits;
		const end_date = date === '' ? '' : new Date(date);
		this.setState(() => ({
			einddatum: date === '' ? '' : tryFormatDate(limits.end_max && end_date > limits.end_max ? limits.end_min : end_date, 1) ?? '',
		}));
	};

	handleDateLimits = (date = '') => {
		const {toewijzing} = this.props.form.data;
		const {periods} = this.props;
		const toewijzing_einddatum = new Date(toewijzing.einddatum ?? FILTER_DATE_MAX);
		const toewijzing_stopdatum = new Date(toewijzing.stop_datum ?? FILTER_DATE_MAX);
		const toewijzing_begindatum = new Date(toewijzing.begindatum ?? '2000-01-01');
		const toewijzing_startdatum = new Date(toewijzing.start_datum ?? '2000-01-01');

		const final_einddatum = toewijzing.verantwoording_buiten_305_307 === 1 ? toewijzing_einddatum : new Date(Math.min(toewijzing_einddatum.getTime(), toewijzing_stopdatum.getTime()));
		const final_begindatum = toewijzing.verantwoording_buiten_305_307 === 1 ? toewijzing_begindatum : new Date(Math.max(toewijzing_begindatum.getTime(), toewijzing_startdatum.getTime()));


		const datum = new Date(date ?? this.state.datum ?? 0);

		const periodlist = periods.map(row => {
			return {
				...row,
				begindatum: new Date(row.begindatum),
				einddatum: new Date(row.einddatum),
			};
		});

		const period = datum.getFullYear() >= 2021 ? 1 : Number(notEmpty(toewijzing.periode) ? toewijzing.periode : 1);

		const period_end = periodlist.find(row => {
			return row.type === period && datum >= row.begindatum && datum <= row.einddatum;
		});

		const final_einddatum_with_period = new Date(Math.min(new Date(period_end?.einddatum ?? FILTER_DATE_MAX).getTime(), final_einddatum.getTime()));

		return {
			start_min: formatDate(final_begindatum, 1),
			start_max: formatDate(final_einddatum, 1),
			end_min: formatDate(new Date(Math.max(new Date(date ?? this.state.datum ?? '2000-01-01').getTime(), final_begindatum.getTime())), 1),
			end_max: formatDate(final_einddatum_with_period, 1),
			period_end: period_end,
		};
	};

	render = () => {
		const {data, formid} = this.props.form;
		const {toewijzing, dialogWidth} = data;
		const {categorieList, productList, eenheidList, limits} = this.state;
		const {autorisatie} = this.props.dbUser;

		const periodString = this.state.datum.length >= 7 ? this.state.datum.substring(0, 7) : 'xxxxxxx';
		const conflicts = formid !== 'verantwoording-toevoegen' ? null : this.props.form.verantwoordingen.find(v => v.datum.startsWith(periodString) && (v.status === 3 || v.status === 5) && v.volume > 0 && v.product === data.product_code);
		const conflictString = !conflicts ? null : 'Deze verantwoording conflicteerd met een ander goedgekeurd verzoek op ' + conflicts.datum;
		console.log({
			periodString,
			conflicts,
			conflictString,
		});

		const style = {
			maxWidth: dialogWidth,
		};

		return (
			<form onSubmit={event => this.handleSubmit(event, data.id)} style={style}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>
				<input type='hidden' name='toewijzing_id' value={toewijzing.id} />
				<input type='hidden' name='gemeente_id' value={toewijzing.gemeente_id} />
				<input type='hidden' name='periode' value={notEmpty(toewijzing.periode) ? toewijzing.periode : 1} />
				<header>
					<h1>
						{formid === 'verantwoording-wijzigen'
							? 'Verantwoording wijzigen'
							: formid === 'verantwoording-verwijderen'
								? 'Verantwoording verwijderen'
								: formid === 'verantwoording-toevoegen'
									? 'Verantwoording toevoegen'
									: null}
					</h1>
				</header>
				{formid !== 'verantwoording-verwijderen' ? (
					<main>
						<label
							htmlFor='f1'
							className={this.state.datum.toString().length === 10 ? 'col2' : 'col2-break'}>
							<span>Begindatum</span>
							<input
								type='date'
								name='datum'
								placeholder='Begindatum'
								id='f1'
								value={this.state.datum}
								required
								onChange={event => this.handleDatum(event.target.value)}
								min={limits.start_min}
								max={limits.start_max}
								autoFocus
							/>
							{!conflictString ? null :
								<span className='error-color'>
									{conflictString}
								</span>
							}
						</label>
						{this.state.datum.toString().length === 10 && (
							<label htmlFor='f2' className='col2'>
								<span>Einddatum (optioneel)</span>
								<input
									type='date'
									name='einddatum'
									placeholder='Einddatum'
									id='f2'
									value={this.state.einddatum || ''}
									onChange={event => this.handleEindDatum(event.target.value)}
									min={limits.end_min}
									max={limits.end_max}
								/>
							</label>
						)}

						<label htmlFor='f7' className='col2'>
							<span>Categorie</span>
							{categorieList.length > 1 ? (
								<select
									name='categorie'
									placeholder='Categorie'
									id='f7'
									value={this.state.categorie.categorie}
									required
									onChange={event => this.handleCategorie(event.target.value)}>
									{categorieList.map(row => (
										<option key={row.categorie} value={row.categorie}>
											{row.categorie_label}
										</option>
									))}
								</select>
							) : (
								<>
									<input
										type='text'
										name='categorie'
										placeholder='Categorie'
										id='f7'
										defaultValue={this.state.categorie.categorie_label}
										disabled={true}
										className='disabled'
									/>
									<input
										type='hidden'
										name='categorie_code'
										defaultValue={this.state.categorie.categorie}
									/>
								</>
							)}
						</label>

						<label htmlFor='f3' className='col2'>
							<span>Product</span>
							{productList.length > 1 ? (
								<select
									name='product'
									placeholder='Product'
									id='f3'
									value={this.state.product.product}
									required
									onChange={event => this.handleProduct(event.target.value)}>
									{productList.map(row => (
										<option key={row.product} value={row.product}>
											{row.product_label}
										</option>
									))}
								</select>
							) : (
								<>
									<input
										type='text'
										name='product'
										placeholder='Product'
										id='f3'
										defaultValue={this.state.product.product_label}
										disabled={true}
										className='disabled'
									/>
									<input
										type='hidden'
										name='product_code'
										defaultValue={this.state.product.product}
									/>
								</>
							)}
						</label>

						<label htmlFor='f4' className='col2'>
							<span>Volume</span>
							<input
								type='number'
								name='volume'
								placeholder='Volume'
								id='f4'
								defaultValue={data.volume ? data.volume : ''}
								required
								min={1}
								max={99999999}
							/>
						</label>

						<label htmlFor='f5' className='col2'>
							<span>Eenheid</span>
							{eenheidList.length > 1 ? (
								<select
									name='eenheid_code'
									placeholder='Eenheid'
									id='f5'
									defaultValue={this.state.eenheid.eenheid}
									required
									onChange={event => this.handleEenheid(event.target.value)}>
									{eenheidList.map(row => (
										<option key={row.eenheid} value={row.eenheid}>
											{row.eenheid_label}
										</option>
									))}
								</select>
							) : (
								<>
									<input
										type='text'
										name='eenheid'
										placeholder='Eenheid'
										id='f5'
										defaultValue={this.state.eenheid.eenheid_label}
										disabled={true}
										className='disabled'
									/>
									<input
										type='hidden'
										name='eenheid_code'
										defaultValue={this.state.eenheid.eenheid}
									/>
								</>
							)}
						</label>

						<input
							type='hidden'
							name='frequentie'
							placeholder='Frequentie'
							id='f6'
							defaultValue={toewijzing.frequentie}
							disabled={true}
							className='disabled'
						/>
						<input type='hidden' name='frequentie_code' defaultValue={toewijzing.frequentie} />
					</main>
				) : (
					<main>{'Weet u zeker dat u deze Verantwoording wilt verwijderen?'}</main>
				)}

				<footer>
					<button type='button' className='secondary' onClick={this.handleCancel}>
                        Annuleer
					</button>
					{isAuthorized(autorisatie, 'functie.uitvoeren') && (
						<button type='submit' disabled={!!conflictString} className='default' autoFocus={formid === 'verantwoording-verwijderen'}>
							{formid === 'verantwoording-verwijderen' ? 'Bevestig verwijdering' : 'Bewaar'}
						</button>
					)}
				</footer>
			</form>
		);
	};
}
